import { graphql, PageProps } from 'gatsby';
import Layout from 'src/components/common/Layout';
import Meta from 'src/components/common/Meta';
import PostList from 'src/components/home/PostList';
import { HOST_NAME } from 'src/constant';
import useSiteMetadata from 'src/hooks/useSiteMetadata';

interface Data {
  allMarkdownRemark: {
    edges: { node: Post }[];
  };
}

interface Context {
  totalPage: number;
  currentPage: number;
  keyword: string;
  keywords: [string, number][];
}

export default function BlogKeyword({ data, pageContext }: PageProps<Data, Context>) {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout visibleTopImage>
      <PostList
        posts={posts}
        totalPage={pageContext.totalPage}
        currentPage={pageContext.currentPage}
        prefixPath={`/keywords/${pageContext.keyword}`}
        keywords={pageContext.keywords}
        currentKeyword={pageContext.keyword}
      />
    </Layout>
  );
}

export const blogKeywordQuery = graphql`
  query blogKeywordQuery($keyword: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { keywords: { in: [$keyword] } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime
          }
          frontmatter {
            author
            title
            date(formatString: "YYYY.MM.DD")
            description
            keywords
          }
        }
      }
    }
  }
`;

export const Head = ({ data, pageContext }: PageProps<Data, Context>) => {
  const sitemeta = useSiteMetadata();
  const posts = data.allMarkdownRemark.edges;

  return (
    <>
      <Meta title={`키워드 - ${pageContext.keyword}`} />
      <link rel="canonical" href={`${sitemeta.siteUrl}/keywords/${pageContext.keyword}`} />
      {pageContext.currentPage === 1 && (
        <script type="application/ld+json">
          {`
          {
            "@context":"https://schema.org",
            "@type":"ItemList",
            "itemListElement": ${JSON.stringify(
              posts.map((post, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                url: `${HOST_NAME}${post.node.fields.slug}`,
              }))
            )}
          }
        `}
        </script>
      )}
    </>
  );
};
